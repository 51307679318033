import type {
  AssistantMessageFE,
  AssistantMessageProductDTO,
  EmbeddedAssistantSettings,
  Option
} from 'ecosystem'
import {
  type Dispatch,
  type MutableRefObject,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  type RefObject
} from 'react'
import {
  type PopoverBodyProps,
  type PopoverContentProps,
  type PopoverProps,
  type StackProps
} from '@chakra-ui/react'
import { type Action } from '../../common/hooks'

export enum ConversationalModeStatus {
  NotInitialized = 'NotInitialized', // The system has not yet been initialized.
  Active = 'Active', // The system is actively engaged in conversation or ready to be engaged.
  Inactive = 'Inactive', // The system is stopped or not active.
  Recording = 'Recording', // The system is actively recording or listening for input.
  Processing = 'Processing', // The system is processing the input.
  ProcessingResponse = 'ProcessingResponse', // The system has processed the input and it's awaiting.
  Responding = 'Responding', // The system is generating a response.
  Error = 'Error' // The system encountered an error.
}

export interface ConversationalMode {
  status: ConversationalModeStatus
  isSupported?: boolean
  isRecording?: boolean
  startRecording?: () => Promise<void>
  stopRecording?: () => Promise<void>
}

type AssistantTeaserProps = Omit<PopoverProps, 'children'> &
  PropsWithChildren<{
    target: ReactElement
    contentProps?: PopoverContentProps
    bodyProps?: PopoverBodyProps
    arrowProps?: PopoverBodyProps
  }>

export interface AssistantContextState {
  onSpeakAudioRef: MutableRefObject<Option<HTMLAudioElement>>
  conversationalMode: ConversationalMode
  dispatchConversationalMode: Dispatch<Action<ConversationalMode>>
  onClose: () => void
  inputValue: string
  setInputValue: (msg: string) => void
  isLoading: boolean
  handleSend: (msg: string) => Promise<void>
  history: AssistantMessageFE[] | null
  inputRef: RefObject<HTMLInputElement>
  isInit: boolean
  initError?: string | null
  reinit: () => void
  errors: Record<AssistantMessageFE['id'], string>
  settings?: Option<EmbeddedAssistantSettings>
  paths?: {
    chat: string
    thread: string
    products: string
  }
  components?: {
    price?: AssistantCustomComponent<
      StackProps,
      { product: AssistantMessageProductDTO; defaultRender: ReactNode }
    >
    teaser?: AssistantCustomComponent<
      Partial<AssistantTeaserProps>,
      { defaultRender: ReactNode },
      AssistantTeaserProps['target']
    >
  }
}

export interface AssistantCustomComponent<
  ChakraProps = any,
  RenderProps = any,
  RenderResult = ReactNode
> {
  isHidden?: boolean
  props?: ChakraProps
  render?: (renderProps: RenderProps) => RenderResult
}

export type AssistantTeaserMode = 'hint' | 'chat' | 'none'

export interface AssistantTextOverrides {
  assistantChatWith?: string
  assistantChatWithFallback?: string
  assistantShrink?: string
  assistantExpand?: string
  assistantMinimize?: string
  assistantReply?: string
  assistantStatusSent?: string
  assistantErrorServiceUnavailable?: string
  assistantErrorUndelivered?: string
  assistantConversationStatusRecording?: string
  assistantConversationStatusProcessing?: string
  assistantConversationStatusProcessingResponse?: string
  assistantConversationStatusProcessingResponding?: string
  assistantConversationStatusError?: string
}
